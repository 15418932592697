<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3333 7C10.3333 3.318 13.318 0.333334 17 0.333334C20.682 0.333334 23.6667 3.318 23.6667 7C23.6667 10.682 20.682 13.6667 17 13.6667C13.318 13.6667 10.3333 10.682 10.3333 7ZM17.6667 7.66667H20.3333V6.33333H17.6667V3.66667H16.3333V6.33333H13.6667V7.66667H16.3333V10.3333H17.6667V7.66667ZM2 12C2 6.93506 5.79394 2.732 10.6866 2.08606C10.403 2.44989 10.1502 2.83888 9.93202 3.24912C7.66725 3.78259 5.73276 5.17312 4.48 7.06C4.69 7.07 4.89 7.07 5.06 7.07C6 7.07 7.46 6.96 7.46 6.96C7.95 6.93 8 7.64 7.52 7.7C7.52 7.7 7.03 7.76 6.49 7.78L9.76 17.53L11.3762 12.6897C11.5742 12.8854 11.7823 13.0709 11.9998 13.2452L9.46 20.62C10.27 20.86 11.12 20.99 12 20.99C13.05 20.99 14.05 20.81 14.98 20.47C14.9667 20.45 14.9533 20.4211 14.94 20.3922C14.9333 20.3778 14.9267 20.3633 14.92 20.35L12.4527 13.5828C13.3833 14.2269 14.4571 14.6787 15.6167 14.8808L16.48 17.46L17.2189 14.9971C17.5525 14.9881 17.881 14.9587 18.2032 14.9101L16.52 19.77C18.5451 18.5927 20.0306 16.6712 20.6601 14.4149C20.6921 14.2999 20.7218 14.1846 20.7492 14.0689C21.1601 13.8505 21.5496 13.5974 21.9139 13.3134C21.268 18.2061 17.0649 22 12 22C6.49 22 2 17.51 2 12ZM3.01 12C3.01 10.7 3.29 9.46 3.79 8.34L8.08 20.09C5.08 18.63 3.01 15.56 3.01 12Z"
      fill="black"
    />
  </svg>
</template>
<script setup lang="ts"></script>
<style scoped></style>
