<template>
  <svg
    width="41"
    height="25"
    viewBox="0 0 41 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="umn-logo text-umn-maroon"
  >
    <path
      d="M38.4331 18.4558L31.2458 6.55112H33.229V0.343018H21.2666V6.54412H23.2497L20.5 11.0916L17.7573 6.54412H19.7334V0.343018H7.77101V6.54412H9.74717L2.5669 18.4558H0V24.6569H12.2015V18.4558H10.0074L13.7487 12.2547L15.1974 14.6581H13.805V20.8592H27.181V14.6581H25.7885L27.2443 12.2547L30.9856 18.4558H28.7985V24.6569H41V18.4558H38.4331Z"
      fill="currentColor"
    />
  </svg>
</template>
<script setup lang="ts"></script>
<style scoped></style>
