<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_906_7883)">
      <rect width="24" height="24" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.58152 4H6.5C5.50544 4 4.55161 4.39509 3.84835 5.09835C3.14509 5.80161 2.75 6.75544 2.75 7.75V17.75C2.75 18.7446 3.14509 19.6984 3.84835 20.4017C4.55161 21.1049 5.50544 21.5 6.5 21.5H16.5C17.4946 21.5 18.4484 21.1049 19.1517 20.4017C19.8549 19.6984 20.25 18.7446 20.25 17.75V14.3123C19.8496 14.4905 19.4319 14.6368 19 14.748V17.75C19 18.413 18.7366 19.0489 18.2678 19.5178C17.7989 19.9866 17.163 20.25 16.5 20.25H6.5C5.83696 20.25 5.20107 19.9866 4.73223 19.5178C4.26339 19.0489 4 18.413 4 17.75V14H7.80125C7.94747 14.8753 8.39938 15.6704 9.07662 16.2439C9.75387 16.8174 10.6126 17.1321 11.5 17.1321C12.3874 17.1321 13.2461 16.8174 13.9234 16.2439C14.4019 15.8387 14.7679 15.3228 14.9931 14.7462C14.5843 14.6406 14.1881 14.5034 13.8073 14.3375C13.6829 14.6357 13.5007 14.9099 13.2678 15.1428C12.7989 15.6116 12.163 15.875 11.5 15.875C10.837 15.875 10.2011 15.6116 9.73223 15.1428C9.26339 14.6739 9 14.038 9 13.375C9 13.2092 8.93415 13.0503 8.81694 12.9331C8.69973 12.8158 8.54076 12.75 8.375 12.75H4V9H9.25204C9.14776 8.59486 9.07442 8.17731 9.03469 7.75H4C4 7.08696 4.26339 6.45107 4.73223 5.98223C5.20107 5.51339 5.83696 5.25 6.5 5.25L9.2067 5.18534C9.30131 4.77738 9.42714 4.38138 9.58152 4Z"
        fill="currentColor"
      />
      <path
        d="M17 0.333334C13.318 0.333334 10.3333 3.318 10.3333 7C10.3333 10.682 13.318 13.6667 17 13.6667C20.682 13.6667 23.6667 10.682 23.6667 7C23.6667 3.318 20.682 0.333334 17 0.333334ZM20.3333 7.66667H17.6667V10.3333H16.3333V7.66667H13.6667V6.33333H16.3333V3.66667H17.6667V6.33333H20.3333V7.66667Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_906_7883">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup lang="ts"></script>
<style scoped></style>
