<template>
  <svg
    width="9"
    height="31"
    viewBox="0 0 9 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.96268 2.86638L6.49786 0.5L4.03304 2.90298V8.47338L0 12.2597V30.5H8.96275V12.2597L9 8.43678L8.96268 2.86638ZM3.80904 26.4223H1.9418V23.182H3.80904V26.4223ZM3.80904 16.8834H1.9418V13.6431H3.80904V16.8834ZM7.24496 21.5799H5.37772V18.3396H7.24496V21.5799ZM7.24496 16.8834H5.37772V13.6431H7.24496V16.8834ZM5.639 5.85182V3.99509L6.46058 3.19412L7.28217 3.99509V5.88836L5.63893 5.88811L5.639 5.85182Z"
      fill="currentColor"
    />
  </svg>
</template>
<script setup lang="ts"></script>
<style scoped></style>
